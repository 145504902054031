._btn_avatar {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  padding: 0;
  img {
    width: 41px;
    height: 41px;
  }
}
.custom_dialog {
  margin-top: 0;
  .card {
    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      border-color: #dbd9df;
      h2 {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0;
      }
      button {
        width: 37px;
        height: 37px;
        background: $gray-secondary;
        border: none;
        border-radius: 50%;
        padding: 0;
        margin: 0;
      }
    }
  }
}

._profile_dialog {
  ._body {
    ._apps {
      padding: 20px;
      background-image: url('/img/common/bg2.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      border: 1px solid #ede4db;
      border-radius: 2px;
      margin-bottom: 40px;
      margin-top: 20px;
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.545455px;
        text-transform: uppercase;
        margin-bottom: 0;
        strong {
          font-weight: bold;
        }
      }
      ._icon {
        margin-top: 1rem;
        img {
          margin-left: 10px;
        }
      }
    }
    a {
      color: $default;
      font-weight: 500;
      font-size: 13px;
    }
  }
  ._footer {
    border-top: 1px solid #e7e6ea;
    margin-bottom: -20px;
    .btn {
      font-size: 12px;
      color: #be8c54;
      font-weight: bold;
    }
  }
}
