.navbar {
  background: $white;
  &.navbar-absolute {
    position: absolute;
    z-index: 1050;
    width: 100%;
  }

  .secondary_nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 1.25rem;
    button {
      background-color: transparent;
      padding-left: 0;
      border: none;
      color: $default;
      font-size: 0.6875rem;
      font-weight: 600;
      &:hover,
      &:focus {
        box-shadow: none;
      }
      &:active {
        background-color: transparent;
        color: $default;
      }
      &.btn-menu {
        padding-right: 0;
        border-bottom: 1px solid $white;
        padding-bottom: 20px;
        margin-right: 0;
        &:not(:first-child) {
          margin-left: 1.6rem;
        }
        &:hover,
        &.active {
          border-bottom: 1px solid $primary;
          background-color: transparent;
          color: $default;
          border-radius: 0;
        }
      }
    }
    .dropdown {
      button {
        &:active {
          background-color: transparent;
          color: $default;
        }
      }
    }
  }
}

.navbar-nav {
  .nav-link {
    font-size: $navbar-nav-link-font-size;
    font-family: $navbar-nav-link-font-family;
    font-weight: $navbar-nav-link-font-weight;
    text-transform: $navbar-nav-link-text-transform;
    letter-spacing: $navbar-nav-link-letter-spacing;
    @include transition($navbar-transition);

    .nav-link-inner--text {
      margin-left: 0.25rem;
    }
  }
}

.navbar-brand {
  font-size: $font-size-sm;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.05px;

  img {
    height: 30px;
  }
}

.navbar-dark {
  .navbar-brand {
    color: $white;
  }
}

.navbar-light {
  .navbar-brand {
    color: $gray-800;
  }
}

.navbar-nav {
  .nav-item {
    .media:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  // .navbar-main {
  //     padding: 1rem 0;
  // }

  .navbar-nav {
    .nav-item {
      margin-right: 0.5rem;
      margin-left: 0.625rem;
      &:last-child {
        margin-right: 0;
      }

      [data-toggle='dropdown']::after {
        transition: $transition-base;
      }

      &.show {
        [data-toggle='dropdown']::after {
          transform: rotate(180deg);
        }
      }
    }
    .nav-link {
      padding-top: $navbar-nav-link-padding-y;
      padding-bottom: $navbar-nav-link-padding-y;
      border-radius: $navbar-nav-link-border-radius;

      i {
        margin-right: 0.625rem;
      }
    }

    .nav-link-icon {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
      font-size: 1rem;
      border-radius: $navbar-nav-link-border-radius;

      i {
        margin-right: 0;
      }
    }

    // Dropdown menu
    .dropdown-menu {
      opacity: 0;
      pointer-events: none;
      margin: 0;

      &:before {
        background: $dropdown-bg;
        box-shadow: none;
        content: '';
        display: block;
        height: 16px;
        width: 16px;
        left: 5px;
        position: absolute;
        bottom: 100%;
        transform: rotate(-45deg) translateY(1rem);
        z-index: -5;
        border-radius: $border-radius-sm;
      }
    }

    .dropdown-menu-right {
      &:before {
        right: 20px;
        left: auto;
      }
    }

    &:not(.navbar-nav-hover) {
      .dropdown-menu {
        &.show {
          opacity: 1;
          pointer-events: auto;
          animation: show-navbar-dropdown 0.25s ease forwards;
        }

        &.close {
          display: block;
          animation: hide-navbar-dropdown 0.15s ease backwards;
        }
      }
    }

    &.navbar-nav-hover {
      .dropdown-menu {
        opacity: 0;
        display: block;
        pointer-events: none;
        transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
        transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
        animation: none;
      }

      .nav-item.dropdown:hover > .dropdown-menu {
        display: block;
        opacity: 1;
        pointer-events: auto;
        visibility: visible;
        transform: translate(0, 0);
        animation: none;
      }

      .nav-item.dropdown > .dropdown-menu {
        .dropdown-item.open + .dropdown-menu {
          display: block;
          opacity: 1;
          pointer-events: auto;
          visibility: visible;
          transform: translate(0, 0);
          animation: none;
        }
        .dropdown-item + .dropdown-menu {
          margin-left: 10px;

          &:before {
            left: -16px;
            top: 4px;
          }
        }
      }
    }

    .dropdown-menu-inner {
      position: relative;
      padding: 1rem;
    }
  }
}

// Transparent navbar

.navbar-transparent {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: transparent !important;
  border: 0;
  box-shadow: none;

  .navbar-brand {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 1);
  }

  .navbar-toggler {
    color: $black;
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }
}

.bg-white {
  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }
}

@include media-breakpoint-up(md) {
  .navbar-transparent {
    .navbar-nav {
      .nav-link {
        color: $navbar-light-color;

        @include hover-focus {
          color: $navbar-light-hover-color;
        }

        &.disabled {
          color: $navbar-light-disabled-color;
        }
      }

      .show > .nav-link,
      .active > .nav-link,
      .nav-link.show,
      .nav-link.active {
        color: $navbar-light-active-color;
      }
    }

    .navbar-brand {
      color: $navbar-light-color;

      @include hover-focus {
        color: $navbar-light-color;
      }
    }
  }
}

// Collapse

.navbar-collapse-header {
  display: none;
}

@include media-breakpoint-down(md) {
  .navbar-nav {
    .nav-link {
      padding: 0.625rem 0;
      color: theme-color('default') !important;
    }

    .dropdown-menu {
      box-shadow: none;
      min-width: auto;

      .media {
        svg {
          width: 30px;
        }
      }
    }
  }

  .navbar-collapse {
    width: calc(100% - 1.4rem);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    margin: 0.7rem;
    overflow-y: auto;
    height: auto !important;
    opacity: 0;

    .navbar-toggler {
      width: 20px;
      height: 20px;
      position: relative;
      cursor: pointer;
      display: inline-block;
      padding: 0;

      span {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        opacity: 1;
        background: #283448;
      }

      :nth-child(1) {
        transform: rotate(135deg);
      }

      :nth-child(2) {
        transform: rotate(-135deg);
      }
    }

    .navbar-collapse-header {
      display: block;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .collapse-brand {
      img {
        height: 36px;
      }
    }

    .collapse-close {
      text-align: right;
    }
  }

  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 1.5rem;
    border-radius: $border-radius;
    background: #fff;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
      0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
    animation: show-navbar-collapse 0.2s ease forwards;
  }

  .navbar-collapse.collapsing-out {
    animation: hide-navbar-collapse 0.2s ease forwards;
  }
}

// Keyframes

@keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hide-navbar-collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
  }

  to {
    opacity: 0;
    transform: scale(0.95);
  }
}

@keyframes show-navbar-dropdown {
  0% {
    opacity: 0;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes hide-navbar-dropdown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate(0, 10px);
  }
}
#navbar-main {
  box-shadow: 0px 1px 0px rgba(25, 5, 48, 0.1),
    0px 2px 6px rgba(25, 5, 48, 0.05);
  padding-bottom: 0;
  top: -1px;
  .navbar-brand {
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      height: 30px;
      width: 1px;
      background-color: rgba($color: $secondary, $alpha: 0.8);
      margin-left: 0.625rem;
      margin-right: 0.625rem;
    }
  }

  .right_nav {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    &._afterLogin {
      ._avatar {
        img {
          width: 41px;
          height: 41px;
        }
      }
    }
  }

  //Dropdown Delivery location/time
  .dropdown.show {
    .dropdown-menu.show.dialog_lv1,
    .dropdown-menu.show.dialog_lv2 {
      padding: 0;
      .popup-dropdown {
        width: 345px;
        .card {
          border-top: 0;
          .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 1.625rem;
            padding-right: 1.625rem;
            background: none;
            border-top: 0;
            border-bottom: 1px solid #dbd9df;
            h2 {
              font-size: 1rem;
              color: $default;
              font-weight: 600;
              margin: 0;
              line-height: 1;
            }
            button {
              display: block;
              margin: 0;
              text-align: center;
              background-color: $gray-secondary;
              width: 37px;
              height: 37px;
              padding: 0;
              border-radius: 50%;
              border: 0;
            }
          }
          .card-body {
            padding-top: 1rem;
            h2 {
              font-weight: 600;
              font-size: 0.75rem;
              margin-top: 2rem;
              margin-bottom: 1rem;
            }
            .input-group {
              .input-group-append {
                margin-left: -2px;
                .input-group-text {
                  color: $primary;
                }
              }
              input {
                font-size: 0.75rem;
              }
            }
          }
          .card-footer {
            padding-left: 1.625rem;
            padding-right: 1.625rem;
            background: none;
            button {
              background-color: $primary;
              padding-right: 0;
            }
          }
        }
      }
    }
    .dropdown-menu.show.dialog_lv1 {
      margin-top: 50px;
      &.arrow_left {
        &:after,
        &:before {
          left: 32px;
        }
      }
      &.arrow_right {
        &:after,
        &:before {
          left: auto;
          right: 32px;
        }
        left: auto !important;
        right: 0;
      }
    }
    .dropdown-menu.show.dialog_lv2 {
      margin: 0;
      &:after,
      &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        margin-bottom: -1px;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 12px;
        margin-left: -12px;
      }
      &:before {
        border-color: rgba(231, 230, 234, 0);
        border-bottom-color: #e7e6ea;
        border-width: 13px;
        margin-left: -13px;
      }
      &.arrow_left {
        &:after,
        &:before {
          left: 32px;
        }
      }
      &.arrow_right {
        &:after,
        &:before {
          left: auto;
          right: 32px;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar {
    .secondary_nav {
      margin-top: 0;
    }
    .dropdown-menu.show.dialog_lv2 {
      position: absolute !important;
    }
    .dropdown-menu.show.dialog_lv1 {
      margin-top: 16px !important;
      transform: translate3d(0px, 0px, 0px) !important;
      &.arrow_right {
        left: 0 !important;
      }
    }
  }
  #navbar-main {
    .right_nav {
      justify-content: center;
      .sign_in_btn {
        margin-left: 1rem;
      }
      .btn {
        font-size: 0.6875rem;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .navbar {
    .secondary_nav {
      .dropdown-menu.show {
        position: fixed !important;
        will-change: unset !important;
        top: 100px !important;
        left: 10px !important;
        transform: unset !important;
        &.arrow_left {
          &:after,
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
