.food_detail {
  background: #ffffff;
  border-radius: 2px;
  ._food-detail-img {
    margin-bottom: 1rem;
    max-height: 500px;
    img {
      width: 100%;
    }
  }
  ._food-detail-img-next {
    width: 128px;
    height: 128px;
    margin-right: 12px;
    margin-top: 12px;
  }
  .image_thumb {
    &:hover {
      cursor: pointer;
    }
    &.active {
      > img {
        border: 2px solid #e1ac6f !important;
      }
    }
  }
  .food_detail__content {
    padding: 0;
    ._header {
      margin: 0px 20px 0px 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e7e6ea;
      h2 {
        font-weight: 500;
        font-size: 0.6875rem;
        line-height: 13px;
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
      h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.818182px;
        text-transform: uppercase;
      }
      p {
        font-size: 0.75rem;
        line-height: 125%;
        text-align: center;
        letter-spacing: 0.545455px;
        margin: 0;
      }
    }
    ._body {
      padding-top: 40px;
      padding-left: 20px;
      padding-right: 20px;
      ._section {
        margin-bottom: 35px;
        .title {
          display: flex;
          margin-bottom: 14px;
          h2 {
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 0.9rem;
            letter-spacing: 0.545455px;
            flex: 1;
          }
          p {
            font-style: normal;
            font-weight: 500;
            font-size: 0.625rem;
            line-height: 0.75rem;
            letter-spacing: 0.454545px;
            text-transform: uppercase;
            margin: 0;
          }
        }
        ._options {
          > :nth-child(even) {
            padding-left: 5px !important;
          }
          > :nth-child(odd) {
            padding-right: 5px !important;
          }
          .custom-control-label {
            display: flex;
            justify-content: space-between;
            span {
              &:last-child {
                padding-right: 5px;
              }
            }
          }
          .add_remove {
            text-align: center;
            span {
              padding-left: 1rem;
              padding-right: 1rem;
              font-size: 0.75rem;
              font-weight: bold;
              color: $default;
            }
            .btn {
              width: 2rem;
              height: 2rem;
              padding: 0;
              margin: 0;
              i {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
    ._footer {
      padding: 6px 20px;
      border-radius: 0px 0px 2px 0px;
      .btn:disabled {
        background-color: #edebf0;
        opacity: 1;
        color: $default;
        border-color: #edebf0;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .modal-body .food_detail.container {
    .food_detail__content {
      ._options {
        > :nth-child(even) {
          padding-left: 0px !important;
        }
        > :nth-child(odd) {
          padding-right: 0px !important;
        }
      }
      > ._body,
      ._footer {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
}
