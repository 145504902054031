// Bootstrap Functions
@import '../../../node_modules/bootstrap/scss/functions';

// Theme Variables
@import 'the-social-design/variables';

// Bootstrap Core
@import '../../../node_modules/bootstrap/scss/bootstrap';

// Theme Core
@import 'the-social-design/theme';

// React Differences
@import 'react/react-differences';

@import './the-social-design/browse_menu';

@import './the-social-design/select';

@import './the-social-design/body.scss';
@import './the-social-design/delivery-location';
@import './the-social-design/custom-dialog';
@import './the-social-design/body4detail';
@import './the-social-design/cart';
@import './the-social-design/review-order';
@import './the-social-design/profile';
@import './the-social-design/food-detail';
@import './the-social-design/delivery_popup';
@import './the-social-design/category_meal.scss';

@import './the-social-design/the-social-custom';
