#main_body {
  #main_body__banner {
    .banner_item {
      height: 277px;
    }
    .carousel-indicators {
      li {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
      }
    }
    .carousel-inner {
      cursor: pointer;
    }
  }
  .category_meal__item {
    margin-top: 3rem;
    text-align: center;
    .category_meal__item_name {
      font-size: 0.75rem;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 1;
    }
    .category_meal__item_description {
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 1rem;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  .form_meal {
    margin-top: -15px;
    .food .card {
      height: 100%;
    }
  }
}
.ant-notification {
  .ant-notification-close-x {
    button {
      width: 37px;
      height: 37px;
      background: #e7e6ea;
      border: none;
      border-radius: 50%;
      padding: 0;
      margin: 0;
    }
  }
  .ant-notification-notice-message {
    font-weight: 700;
    font-size: 15px;
  }
  .ant-notification-notice-btn {
    float: left !important;
    margin-top: 0 !important;
  }
  .ant-statistic-title {
    display: inline-flex;
    margin-left: 5px;
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-statistic-content {
    font-size: 16px !important;
    display: inline-flex;
    float: left;
  }
}
.modal_my_order {
  .ant-statistic {
    display: grid;
    .ant-statistic-title {
      margin-left: 5px;
      font-size: 16px !important;
      color: rgba(0, 0, 0, 0.85) !important;
      grid-column: 2;
      grid-row: 1;
      margin-right: auto;
    }
    .ant-statistic-content {
      font-size: 16px !important;
      grid-column: 1;
      grid-row: 1;
      margin-left: auto;
    }
  }
}
.ant-spin-text {
  color: #e1ac6f !important;
}
.ant-spin-dot-item {
  background-color: #e1ac6f !important;
}
#main_body .ant-affix {
  z-index: 1029 !important;
  background: #ffff;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0 10px 10px 0 rgba(168, 168, 168, 0.2);
  height: auto !important;
}
#browse_menu .browse_menu__category {
  a.active {
    color: #e1ac6f;
  }
}
.category_mobile {
  .slick-slider {
    .slick-slide {
      margin-right: 15px;
      .btn {
        background-color: #edebf0;
        opacity: 1;
        color: #190530;
        border-color: #edebf0;
        font-size: 0.6875rem;
      }
      .btn.active {
        background-color: #e1ac6f;
        border-color: #e1ac6f;
      }
    }
  }
}
.text_over_ellip {
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.food {
  margin-bottom: 2rem;
  &:hover {
    cursor: pointer;
  }
  .card {
    border: none;
    border-radius: 4px;

    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    .menu-item__image {
      border-radius: 4px 4px 0 0;
      background-size: 100%;
      padding-top: 100%;
      position: relative;
      background-position: 50% 55%;
      background-repeat: no-repeat;
      width: 100%;
      z-index: 15;
    }
    .card-body {
      border-radius: 0 0 4px 4px;
      padding-bottom: 1rem;
      color: $default;
      border-top: 0;
      text-align: left;
      z-index: 16;
      background: #fff;
      .food_name,
      .food_price,
      .food_desc {
        font-size: 0.7rem;
      }
      .food_name {
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 0.4rem;
        word-wrap: break-word;
        text-overflow: ellipsis;
      }
      .food_desc {
        text-align: left;
        margin-bottom: 10px;
      }
      .food_price {
        display: flex;
        justify-content: flex-start;
        margin-top: 0;
        .__currency {
          font-size: 8px;
          font-weight: 600;
          text-align: start;
          line-height: 1;
          margin-right: 2px;
        }
        .__price {
          font-size: 0.8rem;
          font-weight: bold;
          line-height: 1;
        }
      }
    }
  }
}
.change_password:hover {
  cursor: pointer;
}
main .header_section .body.form_body {
  padding-top: 33px;
}
@media (max-width: 768px) {
  .form_meal {
    padding-right: 15px !important;
  }
  .category_meal__item {
    .btn_view {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-right: -15px;
    }
  }
  .modal_login {
    min-width: 100%;
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
    word-spacing: 3px;
    .ant-modal-content {
      border-radius: 0;
      height: 100%;
    }
  }
  .social_dialog {
    height: 100%;
    padding: 0;
    margin: 0;
    min-width: 100%;
    .modal-content {
      height: 100%;
      border-radius: 0;
    }
  }
}
@media only screen and (min-width: 481px) and (max-width: 991px) {
  .form_body {
    padding-top: 0px !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  #main_body .ant-affix {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  #meal_list .col {
    padding-right: 0 !important;
  }
  .form_meal {
    .category_meal__item {
      .foods {
        > .row {
          margin-right: 0 !important;
        }
      }
    }
  }
}
@media (min-width: 576px) {
  #main_body > div {
    padding-left: 0 !important;
  }
  .category_mobile {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .food_review.modal-lg {
    max-width: fit-content;
    .modal-body {
      padding: 1rem;
    }
  }
}
.food_review {
}

@include media-breakpoint-up(md) {
  .category_meal__item {
    .food {
      &:nth-child(odd) {
        padding-right: 10px;
      }
      &:nth-child(even) {
        padding-left: 10px;
      }
      .card-body {
        margin-top: -1.5rem;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .category_meal__item {
    .food {
      padding: 0;
      &:nth-child(even) {
        padding-left: 10px;
      }
      &:nth-child(odd) {
        padding-right: 10px;
      }
      .card-body {
        margin-top: -1.5rem;
      }
    }
  }
  #main_body {
    #main_body__banner {
      margin-bottom: 15px;
      .col {
        padding: 0;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  #meal_list {
    .col {
      padding-right: 0;
    }
    .foods {
      padding-right: 0;
    }
  }
}

//Common
.tags {
  .badge {
    cursor: pointer;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
    border-radius: 0.3rem;
    border: 1px solid $primary;
    background-color: transparent;
    color: $primary;
    font-size: 10px;
  }
}

.ant-modal-root {
  .ant-modal-wrap {
    &.login_modal_wrapper {
      overflow: hidden;
    }
  }
}
