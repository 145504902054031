#browse_menu {
  position: fixed;
  z-index: 10;
  h2 {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .browse_menu__category {
    max-width: 200px;
    div {
      a {
        font-size: 0.75rem;
        font-weight: 400;
        text-transform: uppercase;
        color: $default;
        line-height: 1;
        padding-top: 14px;
        padding-bottom: 14px;
        display: block;
        &:hover {
          color: $primary;
          cursor: pointer;
        }
      }
    }
    .tags {
      margin-top: 30px;
      .badge {
        cursor: pointer;
        border: 1px solid $primary;
        background-color: transparent;
        color: $primary;
        &.active {
          color: $default;
          background-color: $primary;
        }
      }
    }
  }
}
