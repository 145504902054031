.delivery-location-dropdown {
  animation-duration: 0s !important;
  @media (max-width: 768px) {
    min-width: 100% !important;
    min-height: 100% !important;
    top: 0 !important;
    left: 0 !important;
  }
}

.popup-dropdown.popup_delivery {
  width: 400px;
  @media (max-width: 768px) {
    margin: 0;
    left: 0;
    right: 0;
    position: fixed;
    top: 0;
    height: 100%;
    bottom: 0;
    width: 100%!important;
  }
  .card {
    border-top: 0;
    @media (max-width: 768px) {
      height: 100%;
    }
    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 1.625rem;
      padding-right: 1.625rem;
      background: none;
      border-top: 0;
      border-bottom: 1px solid #dbd9df;
      @media (max-width: 768px) {
        padding-top: 1.25rem;
      }
      h2 {
        font-size: 1rem;
        color: $default;
        font-weight: 600;
        margin: 0;
        line-height: 1;
      }
      button {
        display: block;
        margin: 0;
        text-align: center;
        background-color: $gray-secondary;
        width: 37px;
        height: 37px;
        padding: 0;
        border-radius: 50%;
        border: 0;
      }
    }
    .card-body {
      padding-top: 0;
      h2 {
        font-weight: 600;
        font-size: 0.75rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
      }
      .input-group {
        .input-group-append {
          margin-left: -2px;
          .input-group-text {
            color: $primary;
          }
        }
        input {
          font-size: 0.75rem;
        }
      }
    }
    .card-footer {
      border-top: solid 1px rgba(0,0,0,0.2);
      padding-left: 1.625rem;
      padding-right: 1.625rem;
      background: none;
      @media (max-width: 768px) {
        padding-bottom: 3rem;
      }

      .delivery-venue-select.ant-select {
        width: 100%;
        height: 40px;
        margin-bottom: 12px;
        .ant-select-selector {
          height: 40px;
        }
      }

      .delivery-venue-select__btn {
        font-size: 14px;
        &:hover, &:active, &:focus {
          color: black;
          border-color: black;
          box-shadow: none;
        }
      }

      button.update-location-btn {
        background-color: $primary;
        padding-right: 0;
      }

    }
  }
}

.delivery-venue-select__modal {
  .ant-radio {
    &.ant-radio-checked {
      .ant-radio-inner {
        border-color: $primary;
        &::after {
          background-color: $primary;
        }
      }
    }
  }
  .update-delivery-venue-btn {
    background-color: $primary;
    box-shadow: none;
    border: none;
    &:hover, &:active, &:focus {
      background-color: $primary;
      color: #000000;
    }
  }
}
