.review_order {
  ._header {
    margin-bottom: 40px;
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 1;
      text-align: center;
      letter-spacing: 0.818182px;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }
  ._body {
    background: #ffffff;
    border: 1px solid #e7e6ea;
    box-shadow: 0px 1px 6px rgba(25, 5, 48, 0.05);
    border-radius: 2px;
    ._details {
      ._list_items {
        hr {
          margin-top: 0;
          margin-bottom: 10px;
        }
      }
      padding: 30px;
      .custom_control_item_type {
        margin-top: 1rem;
        margin-bottom: 0;
        .custom-radio {
          display: flex;
        }
      }
      ._delivery_details {
        margin-top: 35px;
        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 1;
          letter-spacing: 0.545455px;
          text-transform: uppercase;
          margin-bottom: 1rem;
        }
        ._delivery_details_btn {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          ._left {
            display: flex;
            align-items: center;
            flex: 1;
            ._icon {
              width: 36px;
              height: 36px;
              background: #e9e8ec;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 12px;
            }
            ._text {
              p,
              h2 {
                font-style: normal;
                font-weight: 500;
                font-size: 11px;
                line-height: 15px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                margin-bottom: 0;
              }
              h2 {
                font-weight: 600;
              }
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      ._donate {
        padding: 20px;
        background: #fffaf5;
        background-image: url('/img/common/bg2.jpg');
        border: 1px solid #ede4db;
        border-radius: 2px;
        background-repeat: no-repeat;
        background-size: cover;
        h2 {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 0;
          letter-spacing: 0.545455px;
          text-transform: uppercase;
          text-align: center;
        }
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          text-align: center;
          letter-spacing: 0.545455px;
          margin-bottom: 1rem;
        }
        ._moneys {
          text-align: center;
          span {
            background-color: #f2e2d6;
            padding: 10px 14px;
            font-style: normal;
            font-weight: 600;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.5px;
            margin-right: 1rem;
            display: inline-block;
            margin-bottom: 5px;
            border: 1px solid #f2e2d6;
          }
        }
      }
    }
    ._summary {
      padding: 30px;
      box-shadow: -1px 0px 0px #e7e6ea;
      border-radius: 0px 2px 2px 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 1;
        margin-bottom: 35px;
        letter-spacing: 0.545455px;
        text-transform: uppercase;
      }
      ._item {
        display: flex;
        justify-content: space-between;
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 1;
          letter-spacing: 0.545455px;
          text-transform: uppercase;
        }
        &._total {
          p {
            font-weight: bold;
          }
        }
      }
      hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
      .btn {
        font-size: 12px;
        &._link {
          padding: 0;
        }
      }
    }
  }
  &._tracking {
    ._progress {
      height: 65px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background: #fffaf5;
      ._progress_contain {
        background: #fffaf5;
        border-radius: 2px 2px 0px 0px;
        display: flex;
        width: 50%;
        ._step {
          height: 4px;
          background: #eed9ca;
          margin-left: 2px;
          margin-right: 2px;
          &._10 {
            width: 10%;
          }
          &._50 {
            width: 40%;
          }
          &._70 {
            width: 20%;
          }
          &._100 {
            width: 30%;
          }
          &._active {
            background: $primary;
          }
        }
      }
      p {
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 1;
        text-align: center;
        margin-bottom: 0;
        margin-top: 0.5rem;
        letter-spacing: 0.454545px;
        text-transform: uppercase;
      }
    }
    ._total_paid {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      p {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 1;
        letter-spacing: 0.545455px;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }
    ._summary {
      padding: 0;
      ._map {
        //TEMP TODO
        width: 100%;
        height: 210px;
        background-image: url('/img/common/map-test.jpg');
        background-repeat: no-repeat;
        background-size: cover;
      }
      ._address {
        margin-top: 1rem;
        margin-top: 1rem;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.454545px;
        text-transform: uppercase;
      }
      hr {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
      }
      ._driver {
        padding: 30px;
        display: flex;
        justify-content: center;
        justify-items: center;
        flex-direction: column;
        align-items: center;
        ._avatar {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          img {
            width: 52px;
            height: 52px;
            margin-right: 1rem;
          }
          p {
            margin-bottom: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            letter-spacing: 0.454545px;
            text-transform: uppercase;
          }
          ._name {
            font-weight: bold;
          }
          ._bikeID {
            display: inline-block;
            color: $white;
            background: #201a26;
            border-radius: 1px;
            padding: 2px 5px;
          }
        }
      }
    }
  }
}
.modal_promocode {
  .card-header {
    display: inline-block !important;
    > h6 {
      display: inline-block;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    > button {
      float: right;
    }
  }
  .card-body {
    .btn_apply:disabled {
      background-color: #edebf0;
      opacity: 1;
      color: #190530;
      border-color: #edebf0;
    }
    .item_voucher {
      cursor: pointer;
      .badge_vouchers {
        position: absolute;
        right: 20px;
        top: 20px;
        padding-left: 15px;
        padding-right: 15px;
        color: #e1ac6f;
        background: #fff;
        box-shadow: 0px 1px 6px rgba(25, 5, 48, 0.15);
      }
    }
  }
  .detail_voucher {
    .image_brand {
      position: absolute;
      width: 100%;
      height: 230px;
    }
    .btn_back {
      border-radius: 50%;
      padding: 5px;
      width: 37px;
      height: 37px;
      background: #fff;
      border: 0;
      box-shadow: 0px 1px 6px rgba(25, 5, 48, 0.15);
      &:hover {
        > .fa-angle-left {
          color: #e1ac6f;
        }
      }
    }
    .cart_detail_voucher {
      margin-top: 100px;
      border: 0;
      box-shadow: 0px 1px 6px rgba(25, 5, 48, 0.1);
      .card-header {
        > div {
          display: grid;
          > div {
            text-align: center;
            margin: auto;
          }
        }
      }
      .card-body {
        p.card-text {
          color: #5a5a5a;
        }
        .card-title {
          font-size: 0.85rem;
        }
      }
    }
    .badge_vouchers {
      margin-top: 10px;
      position: relative;
      float: right;
      padding-left: 15px;
      padding-right: 15px;
      color: #e1ac6f;
      background: #fff;
      box-shadow: 0px 1px 6px rgba(25, 5, 48, 0.15);
    }
    .badge_vouchers_chanel {
      margin-top: 10px;
      float: right;
      padding-left: 15px;
      padding-right: 15px;
      color: #e1ac6f;
      background: #fff;
      border: 1px solid #e1ac6f;
    }
  }
}
.icon_remove_voucher {
  > i {
    background: black;
    color: #fff;
    text-align: center;
    padding: 4px;
    border-radius: 30px;
    height: 18px;
    width: 18px;
    font-size: 10px;
    margin-left: 5px;
    cursor: pointer;
  }
}
.ant-popover-buttons {
  .ant-btn:hover,
  .ant-btn:focus {
    color: #e1ac6f;
    border-color: #e1ac6f;
  }
  .ant-btn-primary {
    background: #e1ac6f;
    border-color: #e1ac6f;
  }
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    background: #e1ac6f;
    border-color: #e1ac6f;
    color: #fff;
  }
}
.active_donate {
  border: 1px solid #e1ac6f !important;
  background-color: #e1ac6f !important;
}
.icon_refresh {
  top: 12px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
