.footer {
  background: $gray-primary;
  padding: 1.5rem 0;
  position: relative;
  z-index: 11;
  box-shadow: 0 -1px 0 0 rgba(25, 5, 48, 0.1);

  .col-footer {
    .heading {
      color: $footer-heading-color;
      letter-spacing: 0;
      font-size: $footer-heading-font-size;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      margin-bottom: 1rem;
    }
  }

  .nav .nav-item .nav-link,
  .footer-link {
    color: $footer-link-color !important;

    &:hover {
      color: $footer-link-hover-color !important;
    }
  }

  .list-unstyled li a {
    display: inline-block;
    padding: 0.125rem 0;
    color: $footer-link-color;
    font-size: $footer-link-font-size;

    &:hover {
      color: $footer-link-hover-color;
    }
  }

  .copyright {
    font-size: $font-size-sm;
  }

  .dropdown {
    .btn:not(:disabled):not(.disabled):active:focus,
    .btn:not(:disabled):not(.disabled).active:focus {
      box-shadow: none;
    }
  }
}

.footer-dark {
  .col-footer .heading {
    color: $white;
  }
}

.footer.has-cards {
  overflow: hidden;
  padding-top: 500px;
  margin-top: -420px;
  position: relative;
  background: transparent;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 600px;
    height: 2000px;
    background: theme-color('secondary');
    transform: skew(0, -8deg);
  }

  .container {
    pointer-events: auto;
    position: relative;
  }
}

.nav-footer {
  .nav-link {
    font-size: $font-size-sm;
  }

  .nav-item:last-child {
    .nav-link {
      padding-right: 0;
    }
  }
}

.footer__header {
  font-size: 0.6875rem;
  margin-bottom: 1.25rem;
  font-weight: 500;
}

.custom__btn {
  display: flex;
  align-items: center;
  border: 1px solid $gray-secondary;
  border-radius: 2px;
  padding: 0.625rem;
  padding-right: 1rem;
  h2 {
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  span {
    font-size: 0.625rem;
    font-weight: 500;
  }
  .custom__btn_name {
    padding-left: 0.8rem;
  }
  :last-child {
    flex-grow: 1;
    text-align: right;
  }
  &:hover {
    cursor: pointer;
  }
}
.footer__list {
  display: flex;
  .footer__item {
    ul {
      padding-left: 0;
      li {
        display: block;
        font-size: 0.6875rem;
        font-weight: 600;
        margin-bottom: 0.625rem;
        a {
          color: $default;
          line-height: 1;
          i {
            font-size: 1.25rem;
            margin-right: 1rem;
          }
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .footer__list {
    flex-direction: column;
    .footer__item {
      .footer__header {
        margin-top: 1.25rem;
      }
      padding-right: 3.5rem;
    }
  }
}
@include media-breakpoint-up(sm) {
  .footer__list {
    margin-top: 1.25rem;
  }
}
@include media-breakpoint-up(lg) {
  .footer__list {
    margin-top: 0;
    .footer__item {
      padding-right: 3.5rem;
    }
  }
}
.footer {
  padding-top: 2rem;
  hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.footer__second {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  p,
  span {
    font-size: 0.6875rem;
    font-weight: 500;
    margin-bottom: 0;
  }
  a {
    margin-left: 1rem;
  }
}
