.body4detail {
  .body4Detail__header {
    height: 268px;
    background-color: #ffede0;
  }
  .body4Detail__body {
    margin-top: -228px;
    .banner-detail {
      background: #ffffff;
      border: 1px solid #e7e6ea;
      box-shadow: 0px 1px 6px rgba(25, 5, 48, 0.05);
      border-radius: 2px;
      .banner-detail_header {
        h3 {
          font-weight: 500;
          font-size: 0.6875rem;
          line-height: 13px;
          text-align: center;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          margin-top: 27px;
        }
        h2 {
          font-weight: bold;
          font-size: 1.125rem;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0.818182px;
          text-transform: uppercase;
          padding-left: 10%;
          padding-right: 10%;
        }
        padding-bottom: 1.25rem;
        border-bottom: 1px solid #e7e6ea;
        img {
          border-top-left-radius: 2px;
        }
      }
      .banner-detail_content {
        font-weight: 400;
        font-size: 0.8125rem;
        line-height: 23px;
        letter-spacing: 0.590909px;
        padding: 30px;
        padding-top: 22px;
      }
      .banner-detail_more_deals {
        box-shadow: -1px 0px 0px #e7e6ea;
        border-radius: 0px 2px 2px 0px;
        padding: 0;
        display: flex;
        flex-direction: column;

        .list {
          padding: 30px;
          flex: 1;
          .banner-detail_more_deals_title {
            font-weight: 600;
            font-size: 0.75rem;
            line-height: 15px;
            letter-spacing: 0.545455px;
            text-transform: uppercase;
            margin-bottom: 26px;
          }
          .banner-detail_more_deals__list {
            padding-bottom: 16px;
          }
        }

        .subscribe {
          background-image: url('/img/common/bg1.jpg');
          background-repeat: no-repeat;
          background-size: cover;
          padding: 30px;
          h2 {
            font-size: 0.75rem;
            font-weight: bold;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.545455px;
            text-transform: uppercase;
          }
          h3 {
            font-size: 0.75rem;
            font-weight: 400;
            text-align: center;
            letter-spacing: 0.545455px;
          }
          input,
          .input-group-text {
            font-size: 0.75rem;
          }
        }
      }
    }

    .component_detail {
      .component_detail_content {
        padding-left: 0;
        padding-right: 0;
        ._header {
          h1 {
            font-weight: bold;
            font-size: 1.125rem;
            line-height: 1;
            text-align: center;
            letter-spacing: 0.818182px;
            text-transform: uppercase;
          }
          p {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 0.8125rem;
            line-height: 1rem;
            text-align: center;
            letter-spacing: 0.590909px;
          }
          margin-bottom: 40px;
        }
        ._body {
          .row {
            .food {
              padding-left: 5px;
              padding-right: 5px;
            }
            .profile {
              padding: 2rem;
              border: 1px solid #e7e6ea;
              box-shadow: 0px 1px 6px rgba(25, 5, 48, 0.05);
              border-radius: 2px;
              background-color: #fff;
              .update {
                max-width: 300px;
                margin: 0 auto;
                margin-top: 2rem;
              }
            }
            .orders {
              max-width: 600px;
              margin: 0 auto;
              padding: 2rem;
              border: 1px solid #e7e6ea;
              box-shadow: 0px 1px 6px rgba(25, 5, 48, 0.05);
              border-radius: 2px;
              background-color: #fff;
              h2 {
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 1.5rem;
              }
              hr {
                margin-top: 1rem;
                margin-bottom: 1rem;
              }
              ._h_item {
                &:hover {
                  cursor: pointer;
                  box-shadow: 0px 1px 6px rgba(25, 5, 48, 0.05);
                }
              }
            }
          }
        }
      }
    }
  }
  &.meal-plans {
    .body4Detail__header {
      height: 500px;
      background-image: url('/img/common/icon-large-.svg');
      background-repeat: no-repeat;
      background-position-y: -120px;
      & > div {
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          margin-bottom: 20px;
        }
        h1 {
          font-size: 42px;
          margin-bottom: 20px;
        }
        p {
          margin-bottom: 0;
          font-size: 0.875rem;
          font-weight: 300;
        }
      }
    }
    .body4Detail__body {
      .banner-detail_more_deals_title {
        text-align: center;
      }
      .banner-detail_more_deals__list {
        padding-left: 60px;
        padding-right: 60px;
        .restaurant__btn {
          cursor: text;
          line-height: 1;
          img {
            width: 20px;
            height: 20px;
          }
          h2 {
            font-size: 0.75rem;
            line-height: 1rem;
            font-weight: 600;
          }
          span {
            font-size: 9.5px;
            font-weight: 300;
            line-height: 1;
          }
        }
      }
      .subscribe {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 120px;
        max-width: 383px;
        margin: 30px auto;
        border: 1px solid #ede4db;
        background-position-y: -2px;
        border-radius: 2px;
        h1 {
          font-size: 22px;
          margin-top: 1rem;
        }
        .save_up {
          display: flex;
          justify-content: center;
          width: 150px;
          &:after {
            content: '';
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent; /* 40px height (20+20) */
            border-left: 9px solid $primary;
          }
          h2 {
            font-size: 10px;
            padding: 4px 8px;
            background-color: $primary;
            line-height: 1;
            margin-bottom: 0;
          }
        }
      }
      .__desc {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 11px;
      }
      .banner-detail_more_deals {
        box-shadow: none;
        ._sliders {
          ._slider {
            margin-bottom: 30px;
            h2 {
              font-size: 12px;
              line-height: 1;
              margin-bottom: 0;
              font-weight: 600;
              text-align: center;
            }
            p {
              font-size: 12px;
              font-weight: 300;
              text-align: center;
              margin-bottom: 10px;
            }
            .__food-meal-plans {
              max-width: 170px;
              padding: 5px;
              .card {
                height: 210px;
                ._plus {
                  position: absolute;
                  top: 10px;
                  left: 10px;
                  margin-right: 0;
                  border: 0;
                  background-color: $white;
                  color: $default;
                  padding: 7px 11px;
                }
                .card-body {
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  padding-top: 20px;
                  padding-bottom: 20px;
                  background: linear-gradient(
                    180deg,
                    rgba(32, 26, 38, 0.0001) 0%,
                    #201a26 38.88%
                  );
                  .food_name {
                    font-size: 11px;
                    line-height: 13px;
                    font-weight: 600;
                    text-transform: uppercase;
                    text-align: center;
                    color: $white;
                  }
                  .card-img {
                    height: 210px;
                  }
                }
              }
            }
          }
        }
      }
    }
    &._details {
      .body4Detail__header {
        height: 268px;
        h1 {
          font-size: 18px;
          font-weight: bold;
          line-height: 22px;
          margin-bottom: 0;
        }
        p {
          font-size: 11px;
        }
      }
      .body4Detail__body {
        margin-top: -120px;
        .banner-detail_more_deals {
          box-shadow: -1px 0px 0px #e7e6ea;
          .banner-detail_more_deals_title {
            text-align: left;
          }
          .banner-detail_more_deals__list {
            padding: 0;
            .custom_control_item_type {
              .custom-control-label {
                font-size: 11px;
                line-height: 1;
                span {
                  top: 7px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .body4detail {
    .body4Detail__body {
      .food_detail {
        ._img {
        }
        .food_detail__content {
          ._header {
          }
          ._body {
            ._section {
              .title {
              }
              ._options {
                > :nth-child(even) {
                  padding-left: 0px !important;
                }
                > :nth-child(odd) {
                  padding-right: 0px !important;
                }
              }
            }
          }
          ._footer {
          }
        }
      }
    }
  }
}
