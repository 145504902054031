.popup_delivery,
.delivery_time_dialog {
  .ant-tabs-nav-list {
    width: 100%;
    .ant-tabs-tab {
      width: 50%;
      justify-content: center;
      &:hover {
        color: #e1ac6f;
      }
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #e1ac6f;
    }
    .ant-tabs-ink-bar {
      background: #e1ac6f;
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #e1ac6f;
    &::after {
      background-color: #e1ac6f;
    }
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #e1ac6f;
  }
}
.pac-container {
  z-index: 1400;
}
.radio_pick_up {
  label.ant-radio-wrapper {
    > span:nth-child(2) {
      width: 100%;
      padding-right: 0;
    }
    > span.ant-radio,
    .range {
      align-self: center;
    }
    .detail_location {
      font-size: 11px;
    }
  }
}
::placeholder {
  color: #cbc8d0;
  opacity: 1; /* Firefox */
}

@media (max-width: 768px) {
  .display_none_mobile {
    display: none !important;
  }
  #navbar-main .dialog_lv2 {
    .popup_delivery.address_popup,
    .delivery_time_dialog {
      margin: auto;
      left: 0;
      right: 0;
      position: fixed;
      top: 0;
      height: 100%;
      bottom: 0;
      width: 100% !important;
      .card {
        height: 100%;
        border-radius: 0;
        .card-header {
          @media (max-width: 768px) {
            padding-top: 1.25rem;
          }
        }
        .card-footer {
          @media (max-width: 768px) {
            padding-bottom: 3rem;
          }
        }
      }
    }
  }
  .ant-modal-root {
    .ant-modal-centered {
      .modalAddress {
        height: 100%;
        max-width: 100% !important;
        margin: 0;
        padding: 0;
        .ant-modal-content {
          height: 100%;
          border-radius: 0;
        }
      }
    }
  }
  .modal {
    .custom_dialog {
      height: 100%;
      padding: 0;
      margin: 0;
      max-width: 100% !important;
      .modal-content {
        height: 100%;
        border-radius: 0;
      }
    }
  }
}
@media (min-width: 768px) {
  .display_none_desktop {
    display: none !important;
  }
}
