@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.social_dialog {
  margin-top: 0;
  .card {
    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $white;
      border-color: #dbd9df;
      h3 {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 0;
      }
      button {
        width: 37px;
        height: 37px;
        background: $gray-secondary;
        border: none;
        border-radius: 50%;
        padding: 0;
        margin: 0;
      }
    }
    img {
      margin-top: 4rem;
      margin-bottom: 8rem;
    }
    h1 {
      font-size: 1.125rem;
      line-height: 1.5625rem;
      font-weight: 500;
      color: $default;
      margin-bottom: 2rem;
    }
    button {
      margin-bottom: 1rem;
      font-size: 0.75rem;
    }
    p {
      margin-top: 0.625rem;
      font-size: 0.6875rem;
      color: $default;
      font-weight: 500;
    }
    small {
      font-size: 0.625rem;
      font-weight: 400;
      color: rgba($color: $white, $alpha: 0.7);
    }
    .input-group {
      margin-bottom: 2rem;
      .input-group-text {
        border: 0;
        background: #645a62;
        border-right: 0;
        font-size: 0.75rem;
        font-weight: 600;
        color: $white;
      }
      margin-right: -1px;
      input {
        border: 0;
        background: #645a62;
        border-left: 0;
        padding-left: 10px;
        color: $white;
        font-size: 0.75rem;
      }
    }
  }
  &.auth_dialog {
    align-items: unset;
    .card {
      background: transparent;
      h1 {
        color: $white;
      }
      p {
        color: $white;
      }
      small {
        color: rgba($color: $white, $alpha: 0.7);
      }
    }
    .close_modal {
      position: fixed;
      left: 60px;
      top: 60px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      font-size: 1rem;
      background: rgba($color: #979797, $alpha: 0.2);
      z-index: 12;
      border: 0;
      color: $white;
      animation: fadeIn ease 2s;
      -webkit-animation: fadeIn ease 2s;
      -moz-animation: fadeIn ease 2s;
      -o-animation: fadeIn ease 2s;
      -ms-animation: fadeIn ease 2s;
    }
    .modal-content {
      background: transparent;
      box-shadow: none;
    }
    .term-link {
      margin-bottom: 2.5rem;
      color: rgba($color: $white, $alpha: 0.7);
      a {
        color: rgba($color: $white, $alpha: 0.7);
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.7);
      }
    }
    .sign-in-btn {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version*/
      &:hover {
        cursor: pointer;
      }
      &:active,
      &:focus {
        color: rgba($color: $primary, $alpha: 0.7) !important;
      }
    }
  }
  &.bill {
    margin-top: 1.75rem;
    .card-header {
      border-bottom: none;
      padding: 1.75rem 1.5rem;
    }
    .card-body {
      margin-top: -80px;
      padding: 0;
      padding-bottom: 1rem;
      p {
        font-size: 0.8rem;
        margin: 0;
      }
      .phone {
        font-size: 0.8rem;
        font-weight: bold;
        a {
          color: #201a26;
          background-image: linear-gradient(
            to right,
            #201a26 50%,
            transparent 50%
          );
          background-position: 0 1.04em;
          background-repeat: repeat-x;
          background-size: 4px 2px;
          &:hover {
            color: $primary;
            background-image: linear-gradient(
              to right,
              $primary 50%,
              transparent 50%
            );
            background-position: 0 1.04em;
            background-repeat: repeat-x;
            background-size: 4px 2px;
          }
        }
      }
      .order_status {
        font-size: 0.8rem;
        text-transform: uppercase;
      }
      .box {
        padding: 0 2rem;
      }
      h3 {
        font-size: 1rem;
        font-weight: bold;
        margin-top: 2rem;
        text-transform: uppercase;
      }
      h4 {
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }
      .address {
        font-size: 0.8rem;
        opacity: 0.5;
        text-transform: uppercase;
        margin-bottom: 0.2rem;
        display: flex;
        &._bold {
          font-weight: bold;
          opacity: 1;
        }
        .name {
          flex: 1;
        }
        small {
          color: #190530;
        }
      }
      hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}
.auth_dialog__bg {
  &.show {
    background-color: #221a24;
    background: url('/img/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;
  }
}

.social_dialog__bg {
  &.show {
    background-color: #201a26;
    opacity: 0.85;
  }
}
.popup_delivery .ant-tabs-content-holder button {
  background: $primary;
  padding-right: 0;
}
.modalAddress {
  .ant-modal-body {
    padding: 0;
    .address-wrapper {
      .card {
        border: 0;
        .card-footer {
          background: #ffff;
        }
        button.btn_get_location {
          background-color: transparent;
          padding-left: 0;
          border: none;
          color: #190530;
          font-size: 0.7rem;
          font-weight: 600;
          box-shadow: unset;
          text-align: left;
          line-height: 30px;
          &:focus {
            outline: 0;
          }
          .anticon-aim {
            vertical-align: -0.05em;
            font-size: 20px;
          }
        }
      }
    }
  }
}
.ant-modal-wrap {
  z-index: 1400 !important;
}
.ant-modal-close-x {
  line-height: 44px !important;
}
#dynamic_rule .ant-form-item-has-error {
  .input-group-append {
    border-color: #ff4d4f;
  }
}
.restaurant_dialog {
  .restaurant__btn {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 0 !important;
  }
  .restaurant__btn.active {
    background: #e1ac6f;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    width: 100%;
    height: 100%;
  }
}

.brand-notify-popup {
  .ant-modal-content {
    border-radius: 12px;
  }
  .brand-notify-card {
    border: none;
    .brand-notify-card__header {
      background-color: transparent;
      padding: 0;
      border: none;
      font-size: 16px;
      text-align: center;
      strong {
        text-transform: uppercase;
      }
    }
    .brand-notify-card__body {
      padding: 24px 0 12px 0;
      text-align: center;
      font-size: 13px;
    }
    .brand-notify-card__footer {
      background-color: transparent;
      padding: 16px 0 0 0;
      border: none;

      .dont-show-checkbox {
        margin-top: 12px;
        font-size: 13px;

      }
    }
  }

}

.ant-checkbox-wrapper {
  &:hover, &:active, &:focus {
    .ant-checkbox {
      span.ant-checkbox-inner {
        border-color: $primary;
      }
    }
  }
  .ant-checkbox {
    .ant-checkbox-input {
      &:hover, &:focus {
        + .ant-checkbox-inner {
          border-color: $primary;
        }
      }
    }
    &.ant-checkbox-checked {
      &::after {
        border-color: $primary;
      }

      span.ant-checkbox-inner {
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}

.modalConfirm {
  .ant-modal-confirm-btns {
    width: 100%;
    text-align: center;
    .ant-btn {
      width: 50%;
      border: none;
      color: #190530;
      font-size: 0.75rem;
      font-weight: 600;
      height: 40px;
      box-shadow: unset;
    }
    button.ant-btn-primary {
      background-color: #e1ac6f;
      border-color: #e1ac6f;
      margin-left: 0px !important;
    }
  }
  .ant-modal-body {
    padding: 15px !important;
  }
  .ant-modal-confirm-body {
    margin: auto;
    margin-bottom: 15px !important;
    width: 70%;
    .ant-modal-confirm-title {
      text-align: center;
      font-weight: 600;
    }
    .ant-modal-confirm-content {
      text-align: center;
      display: block;
      overflow: hidden;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 600;
      font-size: 16px;
      line-height: 1.4;
      margin-top: 0;
    }
  }
}
@media (max-width: 768px) {
  .restaurant_dialog {
    height: 100%;
    padding: 0;
    margin: 0;
    top: 0 !important;
    margin-top: 0 !important;
    min-width: 100%;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}

@include media-breakpoint-down(xs) {
  .auth_dialog {
    .modal-content {
      .close_modal {
        left: 1rem;
        top: 1rem;
      }
    }
  }
}
