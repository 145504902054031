.the_social_select {
  .the_social_select___control {
    border-radius: 2px;
    font-size: 0.6875rem;
    font-weight: 600;
    height: 43px;
    color: $default;
    &.the_social_select___control--is-focused {
      border-color: $primary !important;
      border-radius: 2px;
      box-shadow: none;
    }
    .the_social_select___indicator-separator {
      display: none;
    }
  }
  .the_social_select___menu {
    font-size: 0.6875rem;
    font-weight: 600;
    margin: 0;
    border-radius: 0 0 2px 2px;
    .the_social_select___menu-list {
      padding: 0;
      .the_social_select___option--is-focused {
        background-color: rgba($color: $primary, $alpha: 0.4);
      }
      .the_social_select___option--is-selected {
        background: $primary;
      }
    }
  }
}
