.btn-cart {
  height: 43px;
  width: 43px;
  border-radius: 50%;
  padding: 0;
  line-height: normal;
  margin-right: 1.6rem;
  background-color: $white;
  color: $default;
  &.btn-cart_custom {
    font-size: 0.875rem;
    i {
      font-size: 0.875rem;
      margin-right: 0;
    }
  }
  &:hover,
  &:active,
  &:focus {
    border-color: $primary !important;
    background-color: $white !important;
    color: $primary !important;
  }
}
main {
  min-height: 600px;
  .header_section {
    padding-top: 115px;
    &:not(.is_logged_in) {
      padding-top: 71px;
    }
    .body {
      padding-top: 39px;
    }
  }
}

html {
  touch-action: manipulation;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.delivery_time_dialog {
  .the_social_select {
    margin-bottom: 2.2rem;
  }
  h2 {
    margin-top: 1.25rem;
  }
  .card-body {
    padding-bottom: 0;
  }
}

.btn_two_icon {
  display: flex;
  border: 1px solid $gray-border !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
  i {
    font-size: 1rem;
    color: $gray-border;
  }
  p {
    margin: 0;
    font-size: 0.6875rem;
    font-weight: 500;
    color: rgba($color: $default, $alpha: 0.7);
    strong {
      font-weight: 600;
      color: rgba($color: $default, $alpha: 0.7);
    }
  }
}

.custom_control_item_type {
  padding: 0.625rem;
  border: 1px solid $gray-border !important;
  border-radius: 2px;
  font-size: 0.6875rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.6875rem;
}

#custom-overlay {
  height: 500px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  height: 100vh;
  background-color: #201a26;
  opacity: 0.85;
}
.ant-modal-mask {
  background-color: #201a26 !important;
  opacity: 0.85;
}
@media (max-width: 768px) {
  .ant-modal-mask {
    z-index: 1399 !important;
  }
}
#top-tooltip {
  &.tooltip-inner {
    max-width: 600px;
    padding: 1rem;
  }
  .top-tooltip_content {
    display: flex;
    p {
      margin: 0;
      font-weight: 600;
      font-size: 0.6875rem;
    }
  }
  img {
    margin-right: 1rem;
  }
}
.restaurant__btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .pick_restaurant__text {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
  }
  img {
    width: 55px;
    height: 55px;
    margin: 0 !important;
  }
  h2 {
    font-size: 0.75rem;
    font-weight: bold;
    color: $default;
    margin: 0;
    text-transform: uppercase;
  }
  p,
  span {
    font-size: 0.625rem;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  a {
    margin-left: 1rem;
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  &:hover {
    cursor: pointer;
  }
}
.close_detail_btn {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #201a26;
  opacity: 0.75;
  border: none;
  color: $white;
  position: absolute;
  top: 20px;
  left: 20px;
  &.second {
    top: 0;
    left: 0;
    background-color: $primary;
    color: $default;
  }
}
.btn.btn-revert {
  color: #fff;
}

.cus-input {
  padding: 12px 14px;
  border: 1px solid #e7e6ea;
  border-radius: 2px;
  background-color: #fff;
  margin-bottom: 25px;
  p {
    color: #190530;
    font-size: 0.6875rem;
    font-weight: 600;
    opacity: 0.5;
    text-transform: uppercase;
    margin-bottom: 0px;
  }
  input {
    color: #190530;
    width: 100%;
    border: none;
    border: 1px solid #fff;
    padding: 0px;
    &[readonly] {
      opacity: 0.5;
    }
  }
}
._h_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  ._img {
    width: 55px;
    height: 55px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    span {
      justify-content: center;
      font-size: 10px;
      line-height: 1;
      background-color: #fff;
      width: 19px;
      height: 19px;
      font-weight: bold;
      display: flex;
      text-align: center;
      align-items: center;
      position: absolute;
      left: 4px;
      bottom: 4px;
      border-radius: 1px;
    }
  }
  ._info {
    align-items: center;
    margin-left: 10px;
    flex: 1;
    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.545455px;
      text-transform: uppercase;
      margin-bottom: 0;
    }
    small {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.545455px;
      text-transform: uppercase;
      color: #525f7f !important;
    }
  }
  ._action {
    text-align: right;
    font-size: 13px;
    button {
      background-color: transparent;
      border: none;
    }
    ._edit {
      color: $default;
    }
    ._remove {
      color: #b92121;
    }
  }
}
.form_edit_cart {
  .ant-pagination-item-active,
  .ant-pagination-item-active:focus-visible,
  .ant-pagination-item-active:hover,
  .ant-pagination-item:focus-visible,
  .ant-pagination-item:hover {
    border-color: #e1ac6f;
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #e1ac6f;
  }
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    color: #e1ac6f;
    border-color: #e1ac6f;
  }
}
.pagination_myorder {
  span.anticon {
    display: block;
  }
}
.modal_my_order {
  .card-header {
    background: #fffaf5 !important;
  }
}
.text_address {
  display: inline-block;
  max-width: 350px;
  overflow: hidden;
  line-height: 1.4;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: text-top;
}
@media (max-width: 768px) {
  .text_address {
    max-width: 250px;
  }
}

.hidden-input .ant-form-item-control-input {
  display: none;
}
