.cart_content {
  @media (max-width: 768px) {
    height: calc(100vh - 250px);
  }
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .c_icon {
    font-size: 30px;
    color: #cbc8d0;
  }

  h2 {
    font-weight: bold;
    font-size: 1rem;
    letter-spacing: 0.727273px;
    text-transform: uppercase;
    margin-bottom: 27px !important;
    margin-top: 27px !important;
  }
}

.cart-icon__number {
  font-size: 12px;
  color: $default;
  font-weight: bold;
  position: absolute;
  left: -2px;
  top: -2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: $primary;
  text-align: center;
}

.cart_has_item {
  align-items: initial;
  justify-content: flex-start;

  ._body {
    flex: 1;
    margin-bottom: 16px;
  }

  ._footer {
  }
}

.ant-message {
  z-index: 2000 !important;

  .anticon {
    top: -3px !important;
  }
}

._h_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  ._img {
    width: 55px;
    height: 55px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    span {
      justify-content: center;
      font-size: 10px;
      line-height: 1;
      background-color: #fff;
      width: 19px;
      height: 19px;
      font-weight: bold;
      display: flex;
      text-align: center;
      align-items: center;
      position: absolute;
      left: 4px;
      bottom: 4px;
      border-radius: 1px;
    }
  }

  ._info {
    align-items: center;
    margin-left: 10px;
    flex: 1;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.545455px;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.545455px;
      text-transform: uppercase;
    }
  }

  ._action {
    text-align: right;
    font-size: 13px;

    button {
      background-color: transparent;
      border: none;
    }

    ._edit {
      color: $default;
    }

    ._remove {
      color: #b92121;
    }
  }
}
