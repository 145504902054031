.category_meal__item {
  .food {
    .card {
      height: 100%;
    }
  }
}
.button_add_to_bag {
  z-index: 19;
  position: absolute;
  bottom: 20px;
  right: 30px;
}
