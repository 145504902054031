.stripe_payment {
  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
    margin-top: 16px;
    display: block;
  }

  button[disabled] {
    opacity: 0.6;
  }

  input {
    display: block;
    border: none;
    font-size: 18px;
    margin: 10px 0 20px 0;
    max-width: 100%;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
    color: #424770;
    letter-spacing: 0.025em;
    width: 500px;
  }

  input::placeholder {
    color: #aab7c4;
  }

  .result,
  .error {
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .error {
    color: #e4584c;
  }

  .result {
    color: #666ee8;
  }

  /* 
  The StripeElement class is applied to the Element container by default.
  More info: https://stripe.com/docs/stripe-js/reference#element-options
  */

  .StripeElement,
  .StripeElementIdeal {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border-radius: 4px;
    background: white;
  }

  .StripeElement--focus,
  .StripeElementIdeal--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
      rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.loading {
    height: 41.6px;
    opacity: 0.6;
  }

  .StripeElementIdeal {
    padding: 0;
  }
}
